import React from 'react';
import { Grid } from "@mui/material";
import Button from '@mui/material/Button';
import './loadmorebutton.scss';


export default function LoadMoreButton() {
    return (
        <>
            <Grid container className="load-more-button">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid className="btn-alignment">
                        <Button className="load-more-btn">load more</Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}