import React from 'react';
import { Grid } from "@mui/material";
import Podcast_image_1 from '../../assets/Podcast image 1.png';
import './podcastTile.scss';


export default function PodcastTile() {    
    return (
        <>
            <Grid container className="episode-container">
                <Grid item xl={3} lg={4} md={4} sm={4} xs={4}>
                    <img src={Podcast_image_1} alt="Podcast_image_1" className="podcast-img" />
                </Grid>
                <Grid item xl={9} lg={8} md={8} sm={8} xs={8} className="podcast-text">
                    <p className="episode-title">
                        how to move into your new home location like an expert
                    </p>
                    <p className="duration">
                        30 MINS
                    </p>
                </Grid>
                <Grid item xl={0} lg={0} md={0} sm={0} xs={0}></Grid>
            </Grid>
        </>
    )
}