import React from 'react'
import Layout from '../../staticcomponents/layout'
import './podcastlanding.scss'
import PodcastLandingHeader from './podcastlandingheader'
import Container from '@material-ui/core/Container';




export default function PodcastLanding() {
    return (
        <>
            <Layout>
                <div className='podcast-landing-main'>
                    <div className='podcast-main-bg'>
                        <div className='capsules-bg-right'>
                            <Container maxWidth="xl" className='podcast-container-div'>
                                <PodcastLandingHeader />
                            </Container>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}