import React from 'react';
import { Grid } from "@mui/material";
import PodcastTile from '../../../staticcomponents/PodcastTile';
import PodcastTileImg from '../../../staticcomponents/PodcastTileImg';
import LoadMoreButton from '../../../staticcomponents/LoadMoreButton';
import './PodcastLandingHeader.scss';

export default function PodcastLandingHeader() {
  
  return (
    <>
      <Grid container className="podcast-landing-header-cls">
        {/* **********************  Podcast Content Header Starts  ********************** */}
        {/* Title Starts */}
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid item xl={2} lg={2} md={0} sm={0} xs={0}></Grid>
          <Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
            <h1 className='podcast-landing-title'>podcasts</h1>
          </Grid>
        </Grid>
        {/* Title Ends */}

        {/* Podcast Tiles Ends */}
        <Grid container className="podcast-landing-container"> 
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid item xl={2} lg={2} md={0} sm={0} xs={0}></Grid>
          <Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
            
            {/* First Container */}
            <Grid container>
              {/* Tile One */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <PodcastTile />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}></Grid>
            </Grid>

            {/* Second Container */}
            <Grid container>
              {/* Tile One */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
                <PodcastTileImg />
              </Grid>

              {/* Tile Two */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
              <PodcastTile />
              </Grid>
            </Grid>

            {/* Third Container */}
            <Grid container>
              {/* Tile One */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
                <PodcastTile />
              </Grid>

              {/* Tile Two */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
              <PodcastTileImg />
              </Grid>
            </Grid>

            {/* Fourth Container */}
            <Grid container>
              {/* Tile One */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}></Grid>

              {/* Tile Two */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
              <PodcastTile />
              </Grid>
            </Grid>

            {/* Fifth Container */}
            <Grid container>
              {/* Tile One */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <PodcastTile />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}></Grid>
            </Grid>

            {/* Sixth Container */}
            <Grid container>
              {/* Tile One */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
                <PodcastTileImg />
              </Grid>

              {/* Tile Two */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
              <PodcastTile />
              </Grid>
            </Grid>

            {/* Seventh Container */}
            <Grid container>
              {/* Tile One */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
                <PodcastTile />
              </Grid>

              {/* Tile Two */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
              <PodcastTileImg />
              </Grid>
            </Grid>

            {/* Eighth Container */}
            <Grid container>
              {/* Tile One */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}></Grid>

              {/* Tile Two */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
              <PodcastTile />
              </Grid>
            </Grid>


          </Grid>
          <Grid item xl={0} lg={0} md={0} sm={0} xs={0}></Grid>
        </Grid>
        </Grid>        
        {/* Podcast Tiles Ends */}

        {/* Load More Butotn Starts */}
        <LoadMoreButton />
        {/* Load More Butotn Ends */}

      </Grid>
    </>
  )
}


